import { Config } from '../../interfaces/config.interface';
import Logo from './logo.jpeg';

const config: Config = {
  name: 'BANORTE',
  title: 'Actividades de voluntariado',
  logo: Logo,
  catalogTalents: true,
  logo_width: '300px',
};

export default config;
